<template>
  <section>
    <section class="grid grid-cols-3 mb-12">
      <p class="uppercase tracking-wide col-span-2 mb-2 text-xl">Sejarah</p>
      <div class="text-right">
        <button class="button --primary" @click="SHOW_HISTORY_FORM()">
          <fa-icon icon="plus"></fa-icon>
          <span class="ml-2 hidden sm:inline-block">Sejarah</span>
        </button>
      </div>
    </section>

    <!-- list -->
    <section class="lg:w-3/4 mx-auto">
      <p v-if="!hasHistories">Belum ada sejarah sekolah</p>
      <item-card
        class="bg-white mb-8 rounded-lg"
        :is-active="true"
        v-for="(item, key) in histories"
        :key="key"
      >
        <!-- Menu -->
        <template v-slot:menu>
          <button class="button" @click="showForm(item)">
            <span>Edit</span>
            <fa-icon icon="pencil-alt"></fa-icon>
          </button>
          <button class="button" @click="triggerDeleteHistory(item)">
            <span>Hapus</span>
            <fa-icon icon="trash-alt"></fa-icon>
          </button>
        </template>

        <!-- Contents -->
        <section class="grid grid-cols-3 gap-4">
          <div>
            <span class="subtle-label">tanggal</span>
            <span class="content">{{
              item.happened_at | indonesian_date_format
            }}</span>
          </div>
          <div class="col-span-2">
            <span class="subtle-label">deskripsi</span>
            <span class="content">{{ item.description }}</span>
          </div>
        </section>
      </item-card>
    </section>

    <!-- form -->
    <history-tree-form></history-tree-form>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { EventBus } from "@/event-bus.js";

export default {
  methods: {
    async triggerDeleteHistory(item) {
      this.hideMenu();
      await this.deleteHistory(item);
    },
    showForm(item) {
      this.hideMenu();
      this.SET_SELECTED_HISTORY(item);
      this.SHOW_HISTORY_FORM();
    },
    hideMenu() {
      EventBus.$emit("item-card.menu.hide");
    },
    ...mapMutations("school_profile", [
      "SHOW_HISTORY_FORM",
      "SET_SELECTED_HISTORY",
    ]),
    ...mapActions("school_profile", ["fetchHistory", "deleteHistory"]),
  },
  computed: {
    hasHistories() {
      return this.histories.length > 0;
    },
    ...mapGetters("school_profile", ["histories"]),
  },
  created() {
    this.fetchHistory();
  },
  name: "HistoryTree",
  components: {
    ItemCard: () => import("@/components/Common/ItemCard.vue"),
    HistoryTreeForm: () =>
      import("@/components/SchoolHistory/HistoryTreeForm.vue"),
  },
};
</script>